/*
---------------------
| Text Format Guide |
---------------------
Logo-Title == size: 100px, spacing: 0.15em
Logo-Subtitle == size: 
--
Text-Title == size: 50px, spacing: 0.15em
Text-Header == size: 25px, spacing: 0.05em
Text-Body == size: 15px
---------------------
| Color Theme Guide |
---------------------
Color Primary == black
Color Secondary == white
Subcolor Primary == #3E8FE2
Background Primary = #222
--
*/



/* 


G L O B A L  


*/
.Global {
  text-align: center;
}
.Global-mobile {
  text-align: center;
  display:grid;
  zoom: 0.5;
  -ms-zoom: 0.5;
  -webkit-zoom: 0.5;
  /* -moz-transform: scale(0.5,0.5);
  -moz-transform-origin: left center; */
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.nav-link {
  color: inherit;
}

.nav-link:active {
  color: inherit;
}

.nav-link:hover {
  color: inherit;
}




/* 


B A C K G R O U N D S 


*/
.BG-1 {
  background: url("background2.png");
  background-color: #999;
}

.BG-2 {
  background: url("background.png");
  /*background-color: #111;*/
}




/* 


H E A D E R  


*/
.Header {
  /*placeholder*/
}

.Header-header {    
  /*placeholder*/
}

.Header-title {
  font-size: 1.5em;
}

.Header-icon {
  color: #3E8FE2; /*rgb(116, 123, 255, 0.75);*/
}

.Header-icon:hover {
  color: lightblue; /*rgb(116, 123, 255, 0.95);*/
}

.Header-link {
  color: rgb(42, 45, 48, 0.75);
  content: '';
  display: block;
  /*width: 25%;*/
  /*padding: .75rem 0;*/
  /*margin: 0;*/
  text-decoration: none;
}

/*.Header-Link:after {
  position: absolute;
  content: '';
  width: 0em; height: 0.05em;
  margin-top: 0em;
  display: block;
  right: 0;
  background: #3E8FE2;
  transition: width .25s ease;
  -webkit-transition: width .25s ease;
}*/

.Header-link:hover {
  color: #3E8FE2;
  text-decoration: none;
  cursor: pointer;
}

.Header-intro {
  font-size: large;
}




/* 


 M A I N 


*/
.Main {
  /* margin-top: 5em;   */
  padding-top: 2em;
  margin-bottom: 5em;
}

.Main-jumbo {
  /* padding-top: 5em;   */
  background: none;
}

.Main-title {
  font-size: 100px;
  font-weight: 100;
}

.Main-title-mobile {
  font-size: 80px;
  font-weight: 100;
}

.Main-subtitle-mobile {
  font-size: 15px;
}

.Main-body {
  padding-top: 2em;
  padding-bottom: 5em;
  margin-left: 25em;
  margin-right: 25em;
}

.Main-body-mobile {
  padding-top: 2em;
  padding-bottom: 5em;
  margin-left: 8em;
  margin-right: 8em;
}

.Main-icons {
  color: #333;
  text-shadow: 1px 1px 1px black;
  margin-left: 0.1em;
  margin-right: 0.1em;
  cursor: pointer;
  transition: all 0.12s ease;
}

.Main-icons-mobile {
  color: #333;
  text-shadow: 1px 1px 1px black;
  margin-left: 0.05em;
  margin-right: 0.05em;
  cursor: pointer;
  transition: all 0.12s ease;
}

.Main-icons:hover {
  color: #3E8FE2;
  transform:scale(1.1);
}

.Main-icons-mobile:hover {
  color: #3E8FE2;
  transform:scale(1.1);
}

.Main-modal {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  text-align: center;
}

.Main-modal-body {
  font-size: 15px;
  font-weight: 50;
  letter-spacing: 1px;
  background-color: #222;
  color: white;
}

.Main-modal-footer {
  justify-content: center;
  background-color: #222;
  border: none;
}

.Main-modal-button {
  backgroud-color: grey;
}

.Main-modal-button:hover {
  backgroud-color:#3E8FE2;
}




/* 

TODO DESIGN:

  - Scroll Down Arrow Animaion
  - Scroll Down Arrow Fades with scrolling
*/

.Main-arrow {
  justify-content: center;
  display: flex;
  height: -5em;
  position: -webkit-sticky;
  position: sticky;
  top: 1em;
}

.Main-arrow-icon {
  color: #333;
  -webkit-animation: FadeInOut 1.75s linear infinite, ZoomInOut 1.75s linear infinite;
  animation: FadeInOut 1.75s linear infinite, ZoomInOut 1.75s linear infinite;
  margin: 0;
  transition: color 0.5s ease;
}

@keyframes FadeInOut {
  0%   { opacity:1; }
  25%  { opacity:0.90;}
  50%  { opacity:0.80;}
  25%  { opacity:0.90;}
  100% { opacity:1; }
}

@-webkit-keyframes FadeInOut {
  0%   { opacity:1; }
  25%  { opacity:0.90;}
  50%  { opacity:0.80;}
  25%  { opacity:0.90;}
  100% { opacity:1; }
}

@keyframes ZoomInOut {
  0%  { transform:scale(1.0); }
  25%  { transform:scale(0.8); }
  50%  { transform:scale(0.7); }
  75%  { transform:scale(0.85); }
  100%  { transform:scale(1.1); }
}

@-webkit-keyframes ZoomInOut {
  0%  { transform:scale(1.0); }
  25%  { transform:scale(0.8); }
  50%  { transform:scale(0.7); }
  75%  { transform:scale(0.85); }
  100%  { transform:scale(1.1); }
}

.Main-arrow-icon:hover {
  color: #3E8FE2;
}



/* 


A B O U T   M E 


*/
.About {
  /* background:
    linear-gradient(175deg, transparent 14.9%, #222 15.1%, #222 74.9%, #222 75.1%); */
  background: #151515;
  color: white;
  padding-top: 2em;
  padding-bottom: 5em;
}

.About-col {
  justify-content: center;
}

.About:after {
    clear: both;
}

.About-title {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 50px;
  font-weight: 5;
  letter-spacing: 0.1em;
  padding-bottom: 2.5%;

  background: none;
}

.About-title-mobile {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 40px;
  font-weight: 5;
  letter-spacing: 0.1em;
  padding-bottom: 2.5%;

  background: none;
}

.About-title:after {
  position: absolute;
  content: '';
  width: 0em; height: 1px;
  margin-top: 0.97em;
  display: block;
  right: 0;
  background: #3E8FE2;
  transition: width .25s ease;
  -webkit-transition: width .25s ease;
}

.About-title:hover {
  outline: none;
  box-shadow: none;
  cursor: default;
}

.About-title:hover:after {
  width: 100%;
  left: 0;
  background: #3E8FE2;
}

.About-title-row {
  overflow: hidden;
  justify-content: center;
  padding-top: 5em;
  padding-bottom: 5em;
}

.About-content-row {
  justify-content: center;
}

.About-content-upper-row-mobile {
  justify-content: center;
  padding-bottom: 2em;
}

.About-content-lower-row-mobile {
  justify-content: center;
  padding-top: 2em;
}

.About-toast {
  font-size: 15px;
  font-weight: 100;
  max-height: 40em;
  max-width: 40em;
  overflow: hidden;
  box-shadow: -2px 2px 5px #111;
  border-color: #222;
  /* margin-left: 5em; */
}

.About-toast-mobile {
  font-size: 12px;
  font-weight: 100;
  max-height: 30em;
  min-width: 50em;
  overflow: hidden;
  box-shadow: -2px 2px 5px #111;
  border-color: #222;
  /* margin-left: 5em; */
}

.About-animate {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 20px;
  font-weight: 5;
  color: white;
  background: #222;
  border-color: #3E8FE2;
  height: 4em;
  width: 50em;
  padding-bottom: 2em;
  padding-left: 1em; 
  padding-right: 1em;
}

.About-animate-mobile {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 16px;
  font-weight: 5;
  color: white;
  background: #222;
  border-color: #3E8FE2;
  height: 4em;
  width: 50em;
  padding-bottom: 2em;
  padding-left: 1em; 
  padding-right: 1em;
}

.About-animate span {
  letter-spacing: 0.05em;
  font-weight: 10;
  font-size: 25px;
  animation: Aboutimate 10s linear infinite 0s;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

.About-animate-mobile span {
  letter-spacing: 0.05em;
  font-weight: 10;
  font-size: 20px;
  animation: Aboutimate-mobile 10s linear infinite 0s;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

.About-animate span:nth-child(2){
  animation-delay: 2s;
}

.About-animate span:nth-child(3){
  animation-delay: 4s;
}

.About-animate span:nth-child(4){
  animation-delay: 6s;
}

.About-animate span:nth-child(5){
  animation-delay: 8s;
}

.About-animate-mobile span:nth-child(2){
  animation-delay: 2s;
}

.About-animate-mobile span:nth-child(3){
  animation-delay: 4s;
}

.About-animate-mobile span:nth-child(4){
  animation-delay: 6s;
}

.About-animate-mobile span:nth-child(5){
  animation-delay: 8s;
}

@keyframes Aboutimate {
  0% { opacity: 0; }
  5% { opacity: 0; transform: translateX(-25px); }
  10% { opacity: 1; transform: translateX(0px); }
  25% { opacity: 1; transform: translateX(0px); }
  30% { opacity: 0; transform: translateX(25px); }
  80% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes Aboutimate-mobile {
  0% { opacity: 0; }
  5% { opacity: 0; transform: translateX(-20px); }
  10% { opacity: 1; transform: translateX(0px); }
  25% { opacity: 1; transform: translateX(0px); }
  30% { opacity: 0; transform: translateX(20px); }
  80% { opacity: 0; }
  100% { opacity: 0; }
}

.About-body {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-weight: 25;
  font-size: 14;
  background: #222;
  justify-items: center;

  padding-top: 1.5em;
  padding-bottom: 1.5em;

  letter-spacing: 0.05em;
  text-align: justify;
}

/* 


C O N T E N T 


*/
.Content{
  clear: both;
  color: black;
  padding-top: 20%;
  padding-bottom: 20%;
  background: 
    linear-gradient(185deg, #222 14.9%, rgb(200, 200, 200, 0.5) 15.1%) /* rgba(51, 51, 51, 1), transparent 3%, transparent 97%,rgba(10, 10, 10, 0.25) 98%, rgb(51, 51, 51, 1) 99%)*/;
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;

}

.Content-title{
  text-align: left;
  font-size: 50px;
  letter-spacing: 0.15em;
  font-weight: 100;
}

.Content-header{
  text-align: left;
  font-size: 25px;
  letter-spacing: 0.05em;
  font-weight: 100;
}

.Content-column{
  height: 15em;
}

.Content-subheader{
  text-align: left;
  font-size: 15px;
  letter-spacing: 0.05em;
  font-weight: 100;
}

.Content-content{
  font-size: 15px;
  font-weight: 100;
  text-align: left;
}

.Content-popoverHeader{
  background: #333;
  color: white;
  font-size: 10px;
  font-weight: 100;
}

.Content-popoverBody{
  background: #444;
  color: white;
  font-size: 8px;
  font-weight: 100;
}

.Content-podcastimg{
  margin-left: 0.25em;
  margin-right: 0.25em;
  font-weight: 100;
  text-align: left;
  text-decoration: none;
  text-decoration-style: none;
  box-shadow: 1px 1px #999;
}

.Content-podcastimg:hover{
  font-weight: 100;
  text-align: left;
  transform:scale(1.025);
  opacity: 0.9;
  box-shadow: 2px 2px #999;
}

.Content-bookimg{
  margin-left: 0.15em;
  margin-right: 0.15em;
  font-weight: 100;
  text-align: left;
  text-decoration: none;
  text-decoration-style: none;
  box-shadow: 1px 1px #999;
}

.Content-bookimg:hover{
  font-weight: 100;
  text-align: left;
  transform:scale(1.025);
  opacity: 0.9;
  box-shadow: 2px 2px #999;
}

.Content-nobutton:focus{
  box-shadow: none;
}

/* 


P O R T F O L I O


*/

/* .Portfolio-title{
  color: lightgrey;
  font-size: 50px;
  letter-spacing: 0.2em;
  font-weight: 100;
  padding-top: 3em;
  padding-bottom: 1em;
  text-align: right;
  display: flex;
  position: relative;
} */

/* .Portfolio-tabs:active {
  background: pink;
  border: none;
} */

.Portfolio-Center-Col-Mobile {
  display: grid;
  /* max-width: 50em; */
  justify-content: center;
}

.Portfolio-BadgeNav {
  margin-left: 25.5em;
  /* justify-self: right; */
}

.Portfolio-BadgeNav-mobile {
  /* position: absolute; */
  /* display: grid; */
  /* margin-left: 29.3em; */
  /* padding-left:25.5em; */
  /* flex-wrap: wrap; */
  justify-self: right;
  margin-top: 2em;
  /* width: 20em; */
  /* background-color:red; */
  /* padding-left: 10em; */
  
}

.Portfolio-badge {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* margin: 0 auto; */

  background: #222;
  border-color: #222;
  border-bottom-color: pink;
  color: white;

  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 25px;
  letter-spacing: 0.05em;
  font-weight: 10;

  width: 8em;
  height: 3em;
  padding-top: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
}

.Portfolio-badge-mobile {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* margin: 0 auto; */

  background: #222;
  border-color: #222;
  border-bottom-color: pink;
  color: white;

  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 20px;
  letter-spacing: 0.05em;
  font-weight: 10;

  width: 8em;
  height: 3em;
  padding-top: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
}

.Portfolio-badge:hover {
  outline: none;
  box-shadow: none;
  background: #272727;
  border: none;
  border-color: #272727;
  transform:scale(1);
  -webkit-transform:scale(1);
}

.Portfolio-badge-mobile:hover {
  outline: none;
  box-shadow: none;
  background: #272727;
  border: none;
  border-color: #272727;
  transform:scale(1);
  -webkit-transform:scale(1);
}

.Portfolio-badge:hover:after {
  width: 100%;
  left: 0;
  background-color: #3E8FE2;
}

.Portfolio-badge-mobile:hover:after {
  width: 100%;
  left: 0;
  background-color: #3E8FE2;
}

.Portfolio-badge:after {
  position: absolute;
  content: '';
  width: 0em; height: 1px;
  margin-top: 2.5em;
  display: block;
  right: 0;
  background-color: #3E8FE2;
  transition: width .25s ease;
  -webkit-transition: width .25s ease;
}

.Portfolio-badge-mobile:after {
  position: absolute;
  content: '';
  width: 0em; height: 1px;
  margin-top: 2.5em;
  display: block;
  right: 0;
  color: lightgrey;
  background-color: #3E8FE2;
  transition: width .25s ease;
  -webkit-transition: width .25s ease;
}

.Portfolio-badge:focus {
  outline: none;
  box-shadow: none;
  background: #222; /* #272727; */
  border-color: none;
  color: lightgrey;
  border-color: #272727;
  border-bottom-color: #3E8FE2;
  border-width: thin;
}

.Portfolio-badge-mobile:focus {
  outline: none;
  box-shadow: none;
  background: #222; /* #272727; */
  border-color: none;
  color: lightgrey;
  border-color: #272727;
  border-bottom-color: #3E8FE2;
  border-width: thin;
}

.Portfolio-card {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 15px;
  font-weight: 1px;
  letter-spacing: 0.05em;
  background-color: #222;
  border-color: none;
  box-shadow: -2px 2px 5px #111;
  margin-top: 1em;
  height: 22em;
  max-width: 54em;
  overflow-y: auto;
}

.Portfolio-card-mobile {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 12px;
  font-weight: 1px;
  letter-spacing: 0.05em;
  background-color: #222;
  border-color: none;
  box-shadow: -2px 2px 5px #111;
  margin-top: 1em;
  max-height: 30em;
  max-width: 50em;
  overflow-y: auto;
}

.Portfolio-header {
  color: lightgrey;
  text-shadow: 1px 1px #444;
  text-align: left;
  letter-spacing: 0.05em;
  font-size: 20px;
  font-weight: 50;
}

.Portfolio-header-mobile {
  color: lightgrey;
  text-shadow: 1px 1px #444;
  text-align: left;
  letter-spacing: 0.05em;
  font-size: 16px;
  font-weight: 50;
}

.Portfolio-body {
  color: white;
  text-align: left;
  font-size: 14px;
  font-weight: 25;
}

.Portfolio-body-mobile {
  color: white;
  text-align: left;
  font-size: 12px;
  font-weight: 25;
}

.Portfolio-row {
  padding-top: 2%;
  padding-bottom: 2%;
}

.Portfolio-href {
  color: #3E8FE2;
}

.Portfolio-href:hover {
  color: #3E8FE2;
  text-decoration: underline;
}



/* 


C O N T A C T


*/

.Contact-row {
  justify-content: center;
  padding-bottom: 2em;
}

.Contact-row-mobile {
  justify-content: center;
  padding-bottom: 2em;
}

.Portfolio {
  background: #151515;
  /* background: rgb(34, 34, 34, 1) */
    /*linear-gradient(185deg, rgba(0, 0, 0, 0) 10.9%, rgb(34, 34, 34, 1) 11.1%)*/;
  padding-top: 5em;
  padding-bottom: 5em;
  text-align: center;
  align-items: center;
}

.Portfolio-mobile {
  display:grid; 
  background: #151515;
  /* background: rgb(34, 34, 34, 1) */
    /*linear-gradient(185deg, rgba(0, 0, 0, 0) 10.9%, rgb(34, 34, 34, 1) 11.1%)*/;
  padding-top: 3em;
  padding-bottom: 3em;
  margin-right: 5em;
  margin-left: 5em;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.Contact {
  align-items: center;
  margin: 0 auto;
  background-color: #222;
  box-shadow: 0px 5px 5px #111;
  width: 45em;
  padding-top: 2em;
  padding-bottom: 3em;
  padding-left: 5em;
  padding-right: 5em;
  margin-top: 3em;
}

.Contact-mobile {
  align-items: center;
  margin: 0 auto;
  background-color: #222;
  box-shadow: 0px 5px 5px #111;
  width: 37.5em;
  padding-top: 2em;
  padding-bottom: 3em;
  padding-left: 5em;
  padding-right: 5em;
  margin-top: 3em;
}

.Contact-badge {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;

  background: none;
  border-color: #151515;
  color: lightgrey;

  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 50px;
  font-weight: 5;
  letter-spacing: 0.1em;

  max-width: 5em;
  /* height: 2em; */
  margin-top: 3em;
}

.Contact-badge-mobile {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;

  background: none;
  border-color: #151515;
  color: lightgrey;

  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 35px;
  font-weight: 5;
  letter-spacing: 0.1em;

  max-width: 7em;
  /* height: 2em; */
  margin-top: 3em;
}

.Contact-badge:after {
  position: absolute;
  content: '';
  width: 0em; height: 1px;
  margin-top: 0.97em;
  display: block;
  right: 0;
  background: #3E8FE2;
  transition: width .25s ease;
  -webkit-transition: width .25s ease;
}

.Contact-badge-mobile:after {
  position: absolute;
  content: '';
  width: 0em; height: 1px;
  margin-top: 0.97em;
  display: block;
  right: 0;
  background: #3E8FE2;
  transition: width .25s ease;
  -webkit-transition: width .25s ease;
}

.Contact-badge:hover {
  outline: none;
  box-shadow: none;
  cursor: default;
  /* background: #272727;
  border-color: #272727; */
  /* transform:scale(1);
  -webkit-transform:scale(1); */
}

.Contact-badge-mobile:hover {
  outline: none;
  box-shadow: none;
  cursor: default;
  /* background: #272727;
  border-color: #272727; */
  /* transform:scale(1);
  -webkit-transform:scale(1); */
}

.Contact-badge:hover:after {
  width: 100%;
  left: 0;
  background: #3E8FE2;
}

.Contact-badge-mobile:hover:after {
  width: 100%;
  left: 0;
  background: #3E8FE2;
}

.Contact-form {
  padding-left: 2em;
  padding-right: 2em;
}

.Contact-label {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 14px;
  font-weight: 2px;
  /* color: white; */
  letter-spacing: 0.05em;
  background-size: 0.5px 1em;
  /* box-shadow:
    inset 0 -0.8em #333,
    inset 0 -0.85em white; */
  display: inline;
  padding-top: 1em;
  padding-bottom: 1em;
}

.Contact-label-mobile {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 12px;
  font-weight: 2px;
  /* color: white; */
  letter-spacing: 0.05em;
  background-size: 0.5px 1em;
  /* box-shadow:
    inset 0 -0.8em #333,
    inset 0 -0.85em white; */
  display: inline;
  padding-top: 1em;
  padding-bottom: 1em;
}

.Contact-input {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  background-color: #222;
  border-color: #222;
  border-bottom-color: lightgrey;
  color: white;
  font-weight: 10;
}

.Contact-input-mobile {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  background-color: #222;
  border-color: #222;
  border-bottom-color: lightgrey;
  color: white;
  font-weight: 10;
  font-size: 12px;
}

.Contact-input:focus {
  background: #252525;
  color: white;
  border-color: #222;
  /* border-top-color: #3E8FE2; */
  border-bottom-color: #3E8FE2;
  cursor: auto;
  box-shadow: none;
  outline: none;
}

.Contact-input-mobile:focus {
  background: #252525;
  color: white;
  border-color: #222;
  /* border-top-color: #3E8FE2; */
  border-bottom-color: #3E8FE2;
  cursor: auto;
  box-shadow: none;
  outline: none;
  font-size: 12px;
}

.Contact-input:-webkit-autofill {
  transition: background-color 99999s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
}

.Contact-input-mobile:-webkit-autofill {
  transition: background-color 99999s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
}

.Contact-button {
  background-color: #252525;
  border-color: rgb(110, 117, 124);
  color: grey;
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 14px;
  font-weight: 25;
  height: 3em;
  width: 10em;
  transition: all 0.12s ease;
}

.Contact-button-mobile {
  background-color: #252525;
  border-color: rgb(110, 117, 124);
  color: grey;
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 12px;
  font-weight: 25;
  height: 3em;
  width: 8em;
  transition: all 0.12s ease;
}

.Contact-button:hover {
  background-color: #333;
  border-color: #3E8FE2;
  color: #3E8FE2;
  font-weight: 100;
  transform:scale(1.05);
}

.Contact-button-mobile:hover {
  background-color: #333;
  border-color: #3E8FE2;
  color: #3E8FE2;
  font-weight: 100;
  transform:scale(1.05);
}

.Contact-honey{
  display: none;
}

/* 


F O O T E R


*/
.Footer {
  display: absolute;
  justify-content: center;
  background: url("mid-section.png");
  color: white;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  fill-opacity: 80%;
}

.Footer-mobile {
  display: absolute;
  justify-content: center;
  background: url("mid-section.png");
  color: white;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  fill-opacity: 80%;
}

.Footer-head {
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 0.1em;
}

.Footer-body{  
  color: white;
  font-size: 15px;
  letter-spacing: 0.05em;
  font-weight: 100;
}

.Footer-body:hover {  
  color: #3E8FE2;
  font-size: 15.5px;
  font-weight: 10;
  text-decoration: none;
}

.Footer-icons {
  color: white;
  transition: all 0.12s ease;
}

.Footer-icons:hover {
  color: lightgrey; /* #3E8FE2 */
  transform:scale(1.25);
  opacity: 0.95;
}

.Footer-foot {
  justify-content: center;
  background-color: none;
  /* background-color:rgb(53, 53, 53) ; */
  /* background-color: rgb(53, 53, 53) rgb(37, 37, 37) #292929; */
  font-size: 8pt;
  color: #888/*rgb(100, 100, 100)*/;
  font-weight: 100;
  bottom: 0;
}

.Footer-foot-mobile {
  justify-content: center;
  background-color: none;
  /* background-color:rgb(53, 53, 53) ; */
  /* background-color: rgb(53, 53, 53) rgb(37, 37, 37) #292929; */
  font-size: 7pt;
  color: #888/*rgb(100, 100, 100)*/;
  font-weight: 100;
  bottom: 0;
}